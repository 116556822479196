import { BarChart, Bar, XAxis, YAxis, Tooltip } from "recharts";
import { FaSun, FaMoon } from "react-icons/fa";

const dataKeys = {
  morningActive: {
    label: "Active",
    icon: <FaSun className="inline text-yellow-400" />,
  },
  morningDisabled: {
    label: "Disabled",
    icon: <FaSun className="inline text-yellow-400" />,
  },
  morningMaintenance: {
    label: "Maintenance",
    icon: <FaSun className="inline text-yellow-400" />,
  },

  nightActive: {
    label: "Active",
    icon: <FaMoon className="inline text-blue-600" />,
  },

  nightDisabled: {
    label: "Disabled",
    icon: <FaMoon className="inline text-blue-600" />,
  },
  nightMaintenance: {
    label: "Maintenance",
    icon: <FaMoon className="inline text-blue-600" />,
  },
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-[#060607] p-2 border border-[#ececec] rounded shadow">
        <p className="font-semibold text-[#ececec]">{`${label}`}</p>
        {payload.map((entry, index) => {
          const dataKey = dataKeys[entry.name];

          // Check if dataKey exists before accessing icon and label
          if (dataKey) {
            return (
              <p
                key={`item-${index}`}
                className="text-sm m-2 flex items-center"
                style={{ color: entry.color }}
              >
                <span className="items-center m-2">{dataKey.icon}</span>
                {`${dataKey.label}: ${entry.value}`}
              </p>
            );
          } else {
            // Fallback in case entry.name is not found in dataKeys
            return (
              <p
                key={`item-${index}`}
                className="text-sm m-2 flex items-center"
              >
                <span className="items-center m-2">🔍</span>
                {`Unknown: ${entry.value}`}
              </p>
            );
          }
        })}
      </div>
    );
  }
  return null;
};

const StatusChart = ({ data }) => {
  return (
    <>
      <BarChart
        data={data}
        width={650}
        height={350}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey="date"
          axisLine={false}
          tickLine={false}
          tick={{ fill: "#ececec" }}
          padding={{ left: 30, right: 30 }}
        />
        <YAxis
          tickLine={false}
          tick={{ fill: "#ececec" }}
          padding={{ left: 30, right: 30 }}
        />
        <Tooltip cursor={{ fill: "transparent" }} content={<CustomTooltip />} />
        <Bar
          dataKey="morningActive"
          stackId="morning"
          fill="#1ce5be"
          radius={[0, 0, 10, 10]}
        />
        <Bar dataKey="morningMaintenance" stackId="morning" fill="#ff5783" />
        <Bar
          dataKey="morningDisabled"
          stackId="morning"
          fill="#457FE5"
          radius={[10, 10, 0, 0]}
        />

        <Bar
          dataKey="nightActive"
          stackId="night"
          fill="#1ce5be"
          radius={[0, 0, 10, 10]}
        />
        <Bar dataKey="nightMaintenance" stackId="night" fill="#ff5783" />
        <Bar
          dataKey="nightDisabled"
          stackId="night"
          fill="#457FE5"
          radius={[10, 10, 0, 0]}
        />
      </BarChart>
    </>
  );
};

export default StatusChart;
