import PerformanceChart from "./PerformanceChart";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getShiftPerformance } from "../../Features/Charts/LineChart/lineChartSlice";
import Spinner from "../../Components/Spinner/Spinner";

const PerformanceCard = () => {
  const dispatch = useDispatch();

  const { performance, isLoading } = useSelector((state) => state.lineChart);

  const [days, setDays] = useState(5);

  useEffect(() => {
    dispatch(getShiftPerformance(days));
  }, [dispatch, days]);

  const handleDayFilter = (selectedDays) => {
    setDays(selectedDays);
  };

  return (
    <>
      <section className="flex items-center justify-center flex-col rounded-lg shadow-[1px_2px_21px_3px_#1ce5be] my-4">
        <div className="flex items-center justify-between w-full">
          <h3 className="text-[#ececec] m-4 text-xl font-bold">Performance</h3>
          <div className="rounded-xl px-4 py-1 flex m-4 items-center bg-[#060607]">
            <ul className="flex justify-around">
              <li
                className={`text-lg mx-2 cursor-pointer ${
                  days === 5 ? "text-[#ff5783] font-semibold" : "text-[#ececec]"
                }`}
                onClick={() => handleDayFilter(5)}
              >
                5 days
              </li>
              <li
                className={`text-lg mx-2 cursor-pointer ${
                  days === 7 ? "text-[#ff5783] font-semibold" : "text-[#ececec]"
                }`}
                onClick={() => handleDayFilter(7)}
              >
                7 days
              </li>
              <li
                className={`text-lg mx-2 cursor-pointer ${
                  days === 14
                    ? "text-[#ff5783] font-semibold"
                    : "text-[#ececec]"
                }`}
                onClick={() => handleDayFilter(14)}
              >
                14 days
              </li>
            </ul>
          </div>
        </div>
        <div className="">
          {isLoading ? <Spinner /> : <PerformanceChart data={performance} />}
        </div>
      </section>
    </>
  );
};

export default PerformanceCard;
