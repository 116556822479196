import React from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const Pagination = ({ currentPage, totalPages, onPrevious, onNext }) => {
  return (
    <div className="flex items-center justify-between w-full mt-4">
      <span className="m-4">
        <FaArrowLeft
          className={`text-xl ${
            currentPage === 0
              ? "invisible"
              : "text-[#1ce5be] cursor-pointer hover:text-[#ff5783]"
          }`}
          onClick={currentPage > 0 ? onPrevious : null}
        />
      </span>
      <span className="text-[#ececec] text-lg">
        Page {currentPage + 1} of {totalPages}
      </span>
      <span className="m-4">
        <FaArrowRight
          className={`text-xl ${
            currentPage === totalPages - 1
              ? "invisible"
              : "text-[#1ce5be] cursor-pointer hover:text-[#ff5783]"
          }`}
          onClick={currentPage < totalPages - 1 ? onNext : null}
        />
      </span>
    </div>
  );
};

export default Pagination;
