import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import lineChartService from "./lineChartService";

const initialState = {
  morningChart: [],
  eveningChart: [],
  nightChart: [],
  performance: [],
  status: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// GET MORNING SHIFT AVERAGE DATA BY DATE RANGE
export const getShiftAverageData = createAsyncThunk(
  "/dashboard/charts/dataRange/morning",
  async (query, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await lineChartService.getShiftAverage(query, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET EVENING SHIFT AVERAGE DATA BY DATE RANGE
export const getEveningAverageData = createAsyncThunk(
  "/dashboard/charts/dataRange/evening",
  async (query, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await lineChartService.getShiftAverage(query, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET EVENING SHIFT AVERAGE DATA BY DATE RANGE
export const getNightAverageData = createAsyncThunk(
  "/dashboard/charts/dataRange/night",
  async (query, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await lineChartService.getShiftAverage(query, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET SHIFT PERFORMANCE DATA
export const getShiftPerformance = createAsyncThunk(
  "/dashboard/charts/performance",
  async (query, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await lineChartService.getPerformanceData(query, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET SHIFT STATUS DATA
export const getShiftStatus = createAsyncThunk(
  "/dashboard/charts/status",
  async (query, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await lineChartService.getShiftStatusData(query, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const lineChartSlice = createSlice({
  name: "lineChart",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShiftAverageData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getShiftAverageData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.morningChart = action.payload;
      })
      .addCase(getShiftAverageData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getEveningAverageData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEveningAverageData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.eveningChart = action.payload;
      })
      .addCase(getEveningAverageData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getNightAverageData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNightAverageData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.nightChart = action.payload;
      })
      .addCase(getNightAverageData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getShiftPerformance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getShiftPerformance.fulfilled, (state, action) => {
        state.isLoading = false;
        state.performance = action.payload;
      })
      .addCase(getShiftPerformance.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getShiftStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getShiftStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = action.payload;
      })
      .addCase(getShiftStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = lineChartSlice.actions;
export default lineChartSlice.reducer;
