export const options = [
  {
    id: 1,
    name: "Batteries",
    value: "batteries",
  },
  {
    id: 2,
    name: "Hikes",
    value: "hikes",
  },
  {
    id: 3,
    name: "Fixed",
    value: "fixed",
  },
  {
    id: 4,
    name: "Fake",
    value: "fake",
  },
  {
    id: 5,
    name: "Left on MTC",
    value: "leftMtc",
  },
  {
    id: 6,
    name: "Picked MTC",
    value: "pickedMtc",
  },
  {
    id: 7,
    name: "Rebalance",
    value: "rebalance",
  },
  {
    id: 8,
    name: "Winter service",
    value: "winterService",
  },
  {
    id: 9,
    name: "Deilibilar service",
    value: "serviceTime",
  },
];
