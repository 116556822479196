import Moment from "react-moment";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLatestCarReports } from "../../../../Features/carReport/carReportSlice";
import { useNavigate, Link } from "react-router-dom";

import Spinner from "../../../Spinner/Spinner";
import Button from "../../../UI/button/Button";
import Checklist from "../../../Checklist/Checklist";

import { FaCheck } from "react-icons/fa";

const ReportTable = ({ latestCarReports, handleRowClick }) => (
  <table>
    <tbody>
      <tr className="text-center">
        <th className="text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          License plate:
        </th>
        <th className="text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          Comment:
        </th>
        <th className="hidden md:table-cell text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          Make & model:
        </th>
        <th className="text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          Employee:
        </th>
        <th className="hidden md:table-cell text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          General checklist:
        </th>
        <th className="hidden md:table-cell text-[#1ce5be] text-sm pr-2 mx-1 text-center pb-2">
          Service Checklist:
        </th>
        <th className="text-[#1ce5be] text-sm pr-3 mx-1 text-center pb-2">
          Created at:
        </th>
      </tr>
      {latestCarReports &&
        latestCarReports.map((report) => (
          <TableRow
            key={report._id}
            report={report}
            handleRowClick={handleRowClick}
          />
        ))}
    </tbody>
  </table>
);

const TableRow = ({ report, handleRowClick }) => (
  <tr
    className="border-b border-[#1ce5be] hover:bg-[#1ce5be]/25 hover:cursor-pointer"
    onClick={() => handleRowClick(report._id)}
  >
    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
      {report.licensePlate}
    </td>
    <td className="flex items-center justify-center pr-3 py-3 text-sm text-center text-[#ececec]">
      {report.comment && report.comment !== "" && (
        <FaCheck className="text-[#1ce5be]" />
      )}
    </td>
    <td className="hidden md:table-cell pr-3 py-3 text-sm text-center text-[#ececec]">
      {report.brand} {report.model}
    </td>
    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
      {report.firstName}
    </td>
    <td className="hidden md:table-cell pr-3 py-3 text-sm text-center text-[#ececec]">
      <Checklist count={report.generalChecklistLength} totalStars={4} />
    </td>
    <td className="hidden md:table-cell pr-3 py-3 text-sm text-center text-[#ececec]">
      <Checklist count={report.serviceChecklistLength} totalStars={7} />
    </td>
    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
      <Moment format="DD-MM-YYYY">{report.createdAt}</Moment>
    </td>
  </tr>
);

const CarReportTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { latestCarReports, isLoading } = useSelector(
    (state) => state.carReport
  );

  const handleRowClick = (id) => {
    navigate(`/home/deilibilarReports/${id}`);
  };

  useEffect(() => {
    dispatch(getLatestCarReports());
  }, [dispatch]);

  return (
    <div className="my-10 rounded-lg shadow-[1px_2px_21px_3px_#1ce5be] px-4">
      <section className="flex items-center flex-col justify-center text-[#ececec]">
        <div className="text-xl my-3 text-[#ff5783] uppercase">
          <h4>Deilibilar reports</h4>
        </div>

        {isLoading ? (
          <Spinner />
        ) : (
          <ReportTable
            latestCarReports={latestCarReports}
            handleRowClick={handleRowClick}
          />
        )}
        <div className="flex items-center justify-end my-5">
          <Link to="/home/deilibilarReports">
            <Button text="View all" />
          </Link>
        </div>
      </section>
    </div>
  );
};

export default CarReportTable;
