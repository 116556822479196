import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import TotalNumberChart from "./TotalNumberChart";
import DatePicker from "./DatePicker";
import Select from "../../Components/UI/select/Select";
import { options } from "./helpers/options";
import Pagination from "./Pagination";
import { getTotalNumbers } from "../../Features/Charts/EmployeeNumbers/employeeChartSlice";
import Spinner from "../../Components/Spinner/Spinner";

const TotalNumberCard = () => {
  const dispatch = useDispatch();

  const { employeeNumbers, isLoading } = useSelector(
    (state) => state.employeeNumbers
  );
  const itemsPerPage = 10;

  const [selectedOption, setSelectedOption] = useState("batteries");
  const [currentPage, setCurrentPage] = useState(0);
  const [dateRange, setDateRange] = useState(null);

  console.log("Initial data", employeeNumbers);
  console.log("Date range fetch:", dateRange, employeeNumbers);

  // Fetch data on initial mount
  useEffect(() => {
    dispatch(getTotalNumbers());
  }, [dispatch]);

  const getFilteredAndSortedData = (employeeNumbers, selectedOption) => {
    if (!employeeNumbers) return [];

    // Sort based on the selected option in descending order
    return [...employeeNumbers].sort(
      (a, b) => b[selectedOption] - a[selectedOption]
    );
  };

  const filteredData = getFilteredAndSortedData(
    employeeNumbers,
    selectedOption
  );

  // Pagination logic
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    currentPage * itemsPerPage,
    currentPage * itemsPerPage + itemsPerPage
  );

  // Handle date change and reset pagination
  const handleDateChange = (value) => {
    setDateRange(value);

    // Convert the new dateRange to ISO format after updating the state
    const isoDate = value.map((date) => date.toISOString());

    // Dispatch the action with the ISO formatted dates
    dispatch(getTotalNumbers(isoDate));

    // Reset pagination to the first page
    setCurrentPage(0);
  };

  // Handle selection change for sorting
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // Handle pagination
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
  };

  return (
    <>
      <section className="flex items-center justify-center flex-col rounded-lg shadow-[1px_2px_21px_3px_#1ce5be] my-4">
        <div className="flex items-center justify-between w-full">
          <h3 className="text-[#ececec] m-4 text-xl font-bold">
            Employee Performance
          </h3>
          <div className="">
            <Select
              options={options}
              value={selectedOption}
              onChange={handleSelectChange}
            />
          </div>
          <div className="px-4 py-1 flex m-4 items-center">
            <DatePicker
              dateRange={dateRange}
              setDateRange={setDateRange}
              handleDateChange={handleDateChange}
            />
          </div>
        </div>
        <div className="">
          {isLoading ? (
            <Spinner />
          ) : (
            <TotalNumberChart
              data={paginatedData}
              selectedOption={selectedOption}
            />
          )}
        </div>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPrevious={handlePreviousPage}
          onNext={handleNextPage}
        />
        {/* <div className="flex items-center justify-between w-full">
          <span className="m-4">
            <FaArrowLeft
              className="text-xl text-[#1ce5be] cursor-pointer hover:text-[#ff5783]"
              onClick={""}
            />
          </span>
          <span className="m-4">
            <FaArrowRight
              className="text-xl text-[#1ce5be] cursor-pointer hover:text-[#ff5783]"
              onClick={""}
            />
          </span>
        </div> */}
      </section>
    </>
  );
};

export default TotalNumberCard;
