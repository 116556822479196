import Moment from "react-moment";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLatestShiftReports } from "../../../../Features/shiftReport/shiftReportSlice";
import { useNavigate } from "react-router-dom";

import Spinner from "../../../Spinner/Spinner";
import ShiftType from "../../../ShiftType/ShiftType";
import ShiftAverage from "../../../ShiftAverage/ShiftAverage";
import Button from "../../../UI/button/Button";

const ReportTable = ({ latestShiftReports, user, handleRowClick }) => (
  <table>
    <tbody>
      <tr className="text-center">
        {user.role === "Admin" ? (
          <th className="text-[#1ce5be] text-sm pr-3 text-center pb-2">
            Shift average:
          </th>
        ) : null}
        <th className="text-[#1ce5be] text-sm pr-3 text-center pb-2">
          Shift type:
        </th>
        <th className="text-[#1ce5be] text-sm hidden md:table-cell pr-3 text-center pb-2">
          Active scooters:
        </th>
        <th className="text-[#1ce5be] text-sm hidden md:table-cell pr-3 text-center pb-2">
          Disabled scooters:
        </th>
        <th className="text-[#1ce5be] text-sm hidden md:table-cell pr-3 text-center pb-2">
          MTC scooters:
        </th>
        <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
          Employee count:
        </th>
        <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
          Employee:
        </th>
        <th className="text-[#1ce5be] text-sm pr-3 mx-2 text-center pb-2">
          Created at:
        </th>
      </tr>
      {latestShiftReports &&
        latestShiftReports.map((report) => (
          <TableRow
            key={report._id}
            report={report}
            handleRowClick={handleRowClick}
            user={user}
          />
        ))}
    </tbody>
  </table>
);

const TableRow = ({ report, handleRowClick, user }) => (
  <tr
    className="border-b border-[#1ce5be] hover:bg-[#1ce5be]/25 hover:cursor-pointer"
    onClick={() => handleRowClick(report._id)}
  >
    {user.role === "Admin" ? (
      <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
        <ShiftAverage score={report.shiftAverage} />
      </td>
    ) : null}
    <td className="text-center text-[#ececec] mt-4 flex items-center justify-center">
      <ShiftType shiftType={report.shiftType} />
    </td>
    <td className="pr-3 py-3 hidden md:table-cell text-sm text-center text-[#ececec]">
      {report.activeScooters}
    </td>
    <td className="pr-3 py-3 hidden md:table-cell text-sm text-center text-[#ececec]">
      {report.disabledScooters}
    </td>
    <td className="pr-3 py-3 hidden md:table-cell text-sm text-center text-[#ececec]">
      {report.maintenanceScooters}
    </td>
    <td className="pr-3 py-2 text-sm text-center text-[#ececec]">
      {report.employeeCount}
    </td>
    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
      {report.firstName}
    </td>
    <td className="pr-3 py-3 text-sm text-center text-[#ececec]">
      <Moment format="DD-MM-YYYY">{report.createdAt}</Moment>
    </td>
  </tr>
);

const ShiftReportTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { latestShiftReports, isLoading } = useSelector(
    (state) => state.shiftReport
  );

  const { user } = useSelector((state) => state.auth);

  const handleRowClick = (id) => {
    navigate(`/dashboard/shiftReports/${id}`);
  };

  const handleViewAll = () => {
    navigate("/dashboard/shiftReports");
  };

  useEffect(() => {
    dispatch(getLatestShiftReports());
  }, [dispatch]);

  return (
    <div className="my-10 mx-2 md:px-5 rounded-lg shadow-[1px_2px_21px_3px_#1ce5be]">
      <section className="flex items-center flex-col justify-center text-[#ececec]">
        <div className="text-xl my-3 text-[#ff5783] uppercase">
          <h4>Shift reports</h4>
        </div>

        {isLoading ? (
          <Spinner />
        ) : (
          <ReportTable
            latestShiftReports={latestShiftReports}
            user={user}
            handleRowClick={handleRowClick}
          />
        )}

        <div className="flex justify-end my-5">
          <Button text="View all" onClick={handleViewAll} />
        </div>
      </section>
    </div>
  );
};

export default ShiftReportTable;
