import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNewestIssue } from "../../../Features/cargoIssues/cargoIssueSlice";
import { useNavigate, Link } from "react-router-dom";
import Moment from "react-moment";

import Spinner from "../../Spinner/Spinner";
import Button from "../../UI/button/Button";
import { MdHideImage } from "react-icons/md";
import LicensePlate from "../../LicensePlate/LicensePlate";

const CargoLatestIssue = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { cargoLatestIssue, isLoading } = useSelector(
    (state) => state.cargoIssues
  );

  useEffect(() => {
    dispatch(getNewestIssue());
  }, [dispatch]);

  const handleLinkBtn = (id) => {
    navigate(`/dashboard/allFleet/cargoVehicles/${id}`);
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <section className="flex items-center justify-center flex-col rounded-lg shadow-[1px_2px_21px_3px_#1ce5be] my-4 mx-4">
          <div className="flex flex-col items-center justify-center">
            {cargoLatestIssue?.image?.length > 0 ? (
              cargoLatestIssue.image.map((item, index) => (
                <Link to={item.url} target="_blank" key={index}>
                  <img
                    src={item.url}
                    className="md:w-72 w-36 mx-5 my-4 hover:cursor-zoom-in"
                    alt="Damage"
                  />
                </Link>
              ))
            ) : (
              <MdHideImage className="text-[#ececec] text-3xl" />
            )}
            {cargoLatestIssue && cargoLatestIssue.cargoData && (
              <div className="flex items-center justify-center flex-col mx-5 my-2">
                <div className="my-2">
                  <LicensePlate
                    plateNumber={cargoLatestIssue.cargoData.licensePlate}
                  />
                </div>
                <p className="text-center">
                  <span className="text-[#ececec] md:text-lg text-md">
                    QR code: #{cargoLatestIssue.cargoData.qrCode}
                  </span>
                </p>
                <p className="text-center ">
                  <span className="text-[#ff5783] md:text-lg text-md">
                    {cargoLatestIssue.description}
                  </span>
                </p>
                <p className="text-center ">
                  <span className="text-[#ececec] md:text-lg text-md">
                    {cargoLatestIssue.userCreated.firstName}
                  </span>
                </p>
                <p className=" text-center">
                  <span className="text-[#ececec] md:text-lg text-md">
                    <Moment format="DD-MM-YYYY HH:mm">
                      {cargoLatestIssue.createdAt}
                    </Moment>
                  </span>
                </p>
              </div>
            )}
          </div>
          <div className="flex my-3">
            <Button
              onClick={() => handleLinkBtn(cargoLatestIssue.deilibilarId)}
              text="View"
            />
          </div>
        </section>
      )}
    </>
  );
};

export default CargoLatestIssue;
