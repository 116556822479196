import { LineChart, Line, XAxis, YAxis, Tooltip } from "recharts";
import { FaSun, FaMoon } from "react-icons/fa";

const dataKeys = {
  morning: {
    label: "Morning",
    icon: <FaSun className="inline text-yellow-400" />,
  },
  night: {
    label: "Night",
    icon: <FaMoon className="inline text-blue-600" />,
  },
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-[#060607] p-2 border border-[#ececec] rounded shadow">
        <p className="font-semibold text-[#ececec]">{`${label}`}</p>
        {payload.map((entry, index) => {
          const dataKey = dataKeys[entry.name];

          // Check if dataKey exists before accessing icon and label
          if (dataKey) {
            return (
              <p
                key={`item-${index}`}
                className="text-sm m-2 flex items-center"
                style={{ color: entry.color }}
              >
                <span className="items-center m-2">{dataKey.icon}</span>
                {`${dataKey.label}: ${entry.value}`}
              </p>
            );
          } else {
            // Fallback in case entry.name is not found in dataKeys
            return (
              <p
                key={`item-${index}`}
                className="text-sm m-2 flex items-center"
              >
                <span className="items-center m-2">🔍</span>
                {`Unknown: ${entry.value}`}
              </p>
            );
          }
        })}
      </div>
    );
  }
  return null;
};

const PerformanceChart = ({ data }) => {
  return (
    <>
      {data && (
        <LineChart width={650} height={350} data={data}>
          <XAxis
            dataKey="date"
            padding={{ left: 30, right: 30 }}
            axisLine={false}
            tickLine={false}
            tick={{ fill: "#ececec" }}
          />
          <YAxis tickLine={false} tick={{ fill: "#ececec" }} />
          <Tooltip content={<CustomTooltip />} />
          <Line
            type="monotone"
            dataKey="morning"
            stroke="#1ce5be"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="night" stroke="#457FE5" />
        </LineChart>
      )}
    </>
  );
};

export default PerformanceChart;
