import React from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip } from "recharts";
import {
  FaBatteryHalf,
  FaHiking,
  FaWrench,
  FaRoute,
  FaHeartBroken,
  FaDizzy,
  FaClock,
  FaSnowflake,
  FaTruck,
} from "react-icons/fa";

const dataKeys = {
  batteries: {
    label: "Batteries",
    icon: <FaBatteryHalf className="inline text-[#1ce5be]" />,
  },
  hikes: {
    label: "Hikes",
    icon: <FaHiking className="inline text-[#ff5783]" />,
  },
  fixed: {
    label: "Fixed",
    icon: <FaWrench className="inline text-[#457FE5]" />,
  },
  fake: {
    label: "Fake",
    icon: <FaHeartBroken className="inline text-[#facc15]" />,
  },
  leftMtc: {
    label: "Left On Maintenance",
    icon: <FaDizzy className="inline text-[#6366f1]" />,
  },
  pickedMtc: {
    label: "Picked Maintenance",
    icon: <FaTruck className="inline text-[#34d399]" />,
  },
  rebalance: {
    label: "Rebalance",
    icon: <FaRoute className="inline text-[#f43f5e]" />,
  },
  winterService: {
    label: "Winter Service",
    icon: <FaSnowflake className="inline text-[#60a5fa]" />,
  },
  serviceTime: {
    label: "Service Time",
    icon: <FaClock className="inline text-[#a78bfa]" />,
  },
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const { firstName, lastName } = payload[0].payload;

    return (
      <div className="bg-[#060607] p-2 border border-[#ececec] rounded shadow">
        <p className="font-semibold text-[#ececec]">
          {`${firstName} ${lastName}`}
        </p>
        {payload.map((entry, index) => {
          const dataKey = dataKeys[entry.name];

          if (dataKey) {
            return (
              <p
                key={`item-${index}`}
                className="text-sm m-2 flex items-center"
                style={{ color: entry.color }}
              >
                <span className="items-center m-2">{dataKey.icon}</span>
                {`${dataKey.label}: ${entry.value}`}
              </p>
            );
          } else {
            return (
              <p
                key={`item-${index}`}
                className="text-sm m-2 flex items-center"
              >
                <span className="items-center m-2">🔍</span>
                {`Unknown: ${entry.value}`}
              </p>
            );
          }
        })}
      </div>
    );
  }
  return null;
};

const TotalNumberChart = ({ data, selectedOption }) => {
  return (
    <>
      <BarChart
        data={data}
        width={850}
        height={450}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey="date"
          axisLine={false}
          tickLine={false}
          tick={{ fill: "#ececec" }}
          padding={{ left: 30, right: 30 }}
        />
        <YAxis
          tickLine={false}
          tick={{ fill: "#ececec" }}
          padding={{ left: 30, right: 30 }}
        />
        <Tooltip cursor={{ fill: "transparent" }} content={<CustomTooltip />} />
        <Bar dataKey={selectedOption} stackId="a" fill="#1ce5be" />
      </BarChart>
    </>
  );
};

export default TotalNumberChart;
