import { DateRangePicker } from "rsuite";
import { FaCalendar } from "react-icons/fa";
import "rsuite/dist/rsuite.min.css";

const DatePicker = ({ dateRange, setDateRange, handleDateChange }) => {
  return (
    <div>
      <DateRangePicker
        value={dateRange}
        onChange={handleDateChange}
        placeholder="Select Date Range"
        size="sm"
        caretAs={() => <FaCalendar color="#060607" />}
        shouldDisableDate={(date) => date > new Date()}
        style={{
          width: "100%",
          backgroundColor: "#060607",
          color: "#ececec",
          borderColor: "#ececec",
        }}
        className="bg-[#060607] !text-[#ececec] !placeholder-[#ececec] !border-[#ececec] hover:!border-[#ff5783]"
      />
    </div>
  );
};

export default DatePicker;
