import axios from "axios";

const API_LINK =
  process.env.REACT_APP_ENV === "Development"
    ? process.env.REACT_APP_DEV_ENV
    : process.env.REACT_APP_API_LINK;

// GET LINE CHART DATA BY DATE RANGE
const getShiftAverage = async (query, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      startDate: query.startDate,
      endDate: query.endDate,
      shiftType: query.shiftType,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/dashboard/charts/shiftAverage`,
    config
  );

  return response.data;
};

// GET PERFORMANCE CHART DATA
const getPerformanceData = async (days, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      days: days,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/dashboard/charts/performance`,
    config
  );

  return response.data;
};

// GET STATUS CHART DATA
const getShiftStatusData = async (days, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      days: days,
    },
  };

  const response = await axios.get(
    `${API_LINK}/api/dashboard/charts/status`,
    config
  );

  return response.data;
};

const lineChartService = {
  getShiftAverage,
  getPerformanceData,
  getShiftStatusData,
};

export default lineChartService;
